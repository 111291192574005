.payment__method {

    .payment__card {
        // width: 600px;
        padding: 3rem 3.5rem;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);



        @media(height:1366px) {
            width: 90%;
            padding: 8vh
        }

        .btn {

            margin-top: .7rem;
            width: 30%;

            @media(max-width:768px) {
                width: 50%;
            }

            @media(max-width: 420px) {
                font-size: 14px;
                width: 75%;
            }

            &:hover {
                color: rgba(104, 103, 103, 0.968) !important
            }
        }
    }

    input {
        border: none;
        outline: none;
        font-weight: 600;
        color: #000;
        width: 100%;
        min-width: unset;
        background-color: transparent;
        border-color: transparent;
        margin: 0;
    }

    .payment__method__item {
        border: 2px solid transparent;
        padding: 0.5rem;
        outline: none;
        width: 100%;
        border-radius: 5px;
        background-color: rgba(221, 228, 236, 0.301);
        cursor: pointer;
    }



}