.register__form {
  .form-control {
    background: transparent;
    font-size: 16px;
    color: rgb(64, 69, 83);
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    transform: none;
    cursor: auto;
    border-image: initial;
    border-bottom: 1px solid rgb(226, 229, 241);

    &:focus {
      box-shadow: none !important;
    }

    &.is-invalid {
      background-image: none;
    }
  }

  label {
    margin: 0;
    color: rgb(126, 133, 155);
    font-size: 1rem;
    max-width: 100%;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all 0.2s ease 0s;
  }

  .eye_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 1.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  button {
    border: none;
  }
}

.add__payment__card {
  .btn {
    width: 100%;
    color: white;
    justify-content: center;
    padding: 2vh 0;
    margin-top: 3vh;
  }

  .btn:focus {
    box-shadow: none;
    outline: none;
    box-shadow: none;
    color: white;
    -webkit-box-shadow: none;
    -webkit-user-select: none;
    transition: none;
  }

  .btn:hover {
    color: white;
  }

  .form-control {
    height: 50px;
    border: 2px solid #eee;
    border-radius: 6px;
    font-size: 14px;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    box-shadow: none;
  }

  .input {
    position: relative;
  }

  .input .input__icon {
    position: absolute;
    top: 16px;
    left: 11px;
    color: #989898;
  }

  .input input {
    text-indent: 25px;
  }

  .certificate-text {
    font-size: 12px;
  }
}
