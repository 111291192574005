      :root {
          --size: 65px;
          --frames: 62;
      }

      .heart {
          display: block;
          width: var(--size);
          height: var(--size);
          cursor: pointer;
          border-radius: 50%;
          margin-left: 7px;
          overflow: visible;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-tap-highlight-color: transparent;
          transition: .3s ease;

          .heart-icon {
              background-image: url("../../Assets/images/icons/heart.svg");
              background-size: calc(var(--size) * var(--frames)) var(--size);
              background-repeat: no-repeat;
              background-position-x: calc(var(--size) * (var(--frames) * -1 + 2));
              background-position-y: calc(var(--size) * 0.02);
              width: var(--size);
              height: var(--size);

          }

          &.active {
              .heart-icon {
                  animation: heart 1s steps(calc(var(--frames) - 3));
                  animation-fill-mode: forwards;
              }
          }

          &:hover {
              background-color: #e1255e15;

              & .heart-icon {
                  background-position-x: calc(var(--size) * (var(--frames) * -1 + 1));
              }
          }

      }


      @keyframes heart {
          0% {
              background-position-x: 0;
          }

          100% {
              background-position-x: calc(var(--size) * (var(--frames) * -1 + 3));
          }
      }