.product__page {

    .product__Img_preview {
        img {
            width: 400px;
            max-height: 400px;
        }
    }

    .btn-social {
        font-size: 1.5rem;
    }

    .Product__info {
        background-color: #f7f7fc !important;

        .Product__Title {
            color: #333;
        }
    }
}