.OrderConfirmation {
    margin: auto;

    .icon__container {

        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        width: 90px;
        border: 2px solid #eee;
        border-radius: 50%;
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    button {
        border-radius: 5px;
    }

}