@import "../scss/colors";

:root {
  --body-font-family: "Open Sans", sans-serif;
  --head-font-family: "Open Sans", sans-serif;
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --head-font-weight: 600;
  --body-line-height: 1.5;
  --body-color: #737491;
  --body-bg: #fff;
}

body {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  text-align: var(--body-text-align);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--head-font-family);
  font-weight: var(--head-font-weight);
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s linear;

  &:hover {
    color: $main-color !important;
  }
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* Buttons */
.btn:focus,
button:focus {
  outline: 0;
  box-shadow: unset !important;
}

.btn-primary {
  color: #fff !important;

  &:hover {
    color: #fff !important;
  }
}

.btn-outline-primary {
  &:hover {
    color: #fff !important;
  }
}

.btn-outline-gray-800 {
  &:hover {
    color: #fff !important;
  }
}

.btn-check:focus + .btn-primary,
.btn-primary:active,
.btn-primary:focus {
  box-shadow: unset !important;
}

.btn-wishlist .btn-tooltip,
.btn-addtocart .btn-tooltip,
.btn-remove .btn-tooltip,
.btn-details .btn-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  padding: 0.2084375rem 0.416875rem;
  transform: translateX(-50%);
  transition: opacity 0.2s ease-in-out;
  border-radius: 0.375rem;
  background-color: #37384e;
  color: #fff;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
}

.btn-translucent-light {
  background-color: rgba(255, 255, 255, 0.08);
  color: #fff;
  font-weight: bold;

  &:hover {
    background-color: #fff;
    color: #37384e;
  }

  &:focus {
    box-shadow: unset;
  }
}

/* Form[inputs] */
// input[type="number"]:in-range {
//     border-color: rgba(0, 255, 0, 0.25);
// }

// input[type="number"]:out-of-range {
//     border-color: rgba(255, 0, 0, 0.25);
// }

.dropdown-toggle {
  &::after {
    border: none !important;
  }
}

.navbar-toggler {
  color: #465963 !important;
  border-radius: 0;
  transition: 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: unset !important;
  }
}

.card-shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    // box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25),
    //     0 0 7px rgba(0, 0, 0, 0.22);
    box-shadow: 6px 11px 41px -28px #a99de7;
  }
}

.shadow-sm {
  transition: 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  &:hover {
    transform: scale(1.1);
  }
}

/* fon size */
.fs-xs {
  font-size: 0.75rem !important;
}

.fs-md {
  font-size: 0.9375rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.star-rating {
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  vertical-align: middle;

  .active {
    color: #ffb15c;
  }
}

.border-text {
  position: relative;
  display: block;
  z-index: 1;

  &::before {
    height: 10px;
    width: 100%;
    background: $main-color;
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    opacity: 0.5;
  }
}

/* scrollbar **/
body,
.offcanvas-body,
.order__sidebar .cart__summary__items {
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: none;
  }

  transition: 0.3s;
  scroll-behavior: smooth;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $main-color !important;
    }
  }
}
.max-height-500 {
  max-height: 500px !important;
}
.p-sm-fixed {
  @media (max-width: 500px) and (min-width: 200px) {
    position: fixed;
    bottom: -15px;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 15px 5px;
    background: #fff;
    box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
  }
}

.x-hidden {
  overflow-x: hidden;
}

.mt-n5 {
  margin-top: -5rem;
}

.mt-n6 {
  margin-top: -6rem;
}

.mt-n7 {
  margin-top: -7rem;
}

// Remove Arrows/Spinners

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
