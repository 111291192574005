@import '../../../scss/colors';

.main-button {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    z-index: 22;
}

.main-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: $main-color;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.main-button span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #234567;
}

.main-button svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #234567;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.main-button:hover:before {
    width: 100%;
    background: $main-color;
}

.main-button:hover svg {
    transform: translateX(0);
}

.main-button:active {
    transform: scale(0.95);
}