@import "../../scss/colors";

.checkout__page {
  margin-top: -6.5rem;

  .checkout-body {
    @media (min-width: 992px) {
      padding-left: 0.9375rem;
      padding-right: 2.5rem;
    }

    .checkout-heading {}

    .checkout-content {
      input {
        &::placeholder {
          color: rgba(138, 134, 134, 0.383);
        }
      }
    }
  }

  .order__sidebar {
    &.height-300 {
      height: 300px !important;
    }

    .cart__summary__items {
      max-height: 350px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .navigation__bnts {
    display: flex;
    align-items: center;
    justify-content: center;

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      padding-bottom: 1rem;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.5) 3px 2px 15px;
      width: 100%;
      text-align: center;
      z-index: 999;
    }
  }
}

.steps {
  display: flex;
  width: 100%;

  .step-item {
    flex-basis: 0;
    flex-grow: 1;
    transition: color 0.25s ease-in-out;
    text-align: center;
    text-decoration: none !important;
    color: rgba(255, 255, 255, 0.55);

    .step-progress {
      position: relative;
      width: 100%;
      height: 0.25rem;
      color: #fff;
      background-color: #485268;

      .step-count {
        position: absolute;
        top: -0.75rem;
        left: 50%;
        width: 1.625rem;
        height: 1.625rem;
        margin-left: -0.8125rem;
        border-radius: 50%;
        font-size: 0.875rem;
        line-height: 1.625rem;
        background-color: #485268;
      }
    }

    .step-label {
      padding-top: 1.5625rem;

      .label-icon {
        margin-top: -0.25rem;
        margin-right: 0.425rem;
        font-size: 1.2em;
        vertical-align: middle;
      }
    }

    &.active {
      .step-progress {
        color: #fff;
        background-color: #fe696a;

        .step-count {
          color: #fff;
          background-color: #fe696a;
        }
      }

      &.current {
        color: #fff;
        pointer-events: none;
      }
    }
  }
}

.payment__cards {
  .nav-pills .nav-link {
    cursor: pointer;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 2px 0px;
    border-radius: 2px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 100%;
    color: rgb(178, 187, 210);
    border: 1px solid rgb(226, 229, 241);

    &.active {
      border-color: $main-color;
      background-color: $main-color;
      color: #fff;

      &:hover {
        color: #fff !important;
      }
    }
  }
}