@import "../../../../scss/colors";

.navbar {
    height: 80px;
    z-index: 999;

    &.navbar-light {
        background-color: #fff !important;
    }

    .navbar-brand {
        position: relative;

        img {
            max-height: 60px !important;
        }

        .sub-logo {
            margin-left: -10px;
            font-size: 1.3rem;
            transition: color 0.3s ease-in;
        }

        small {
            position: absolute;
            top: 9px;

            font-size: 14px;
            transition: .2s;
        }

        &:hover {
            .sub-logo {
                color: #465963 !important;
            }
        }
    }

    .btn__toggle {
        // color: $main-color !important;
        // box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.3);

        &:hover,
        &:active,
        &:focus {
            color: $orange !important;
        }
    }

    .navbar-toggle {
        padding: 0.75rem 0.75rem;
        font-size: 1rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0;
        transition: box-shadow .15s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border: none;
            box-shadow: unset;
        }
    }

    .navbar-tool {
        position: relative;
        color: #5a5b75;
        cursor: pointer;

        .navbar-tool-icon-box {
            position: relative;
            // width: 2.75rem;
            // height: 2.75rem;
            font-size: 1.375rem;
            text-align: center;

            span {
                font-size: 0.9rem;
            }
        }

        .navbar-tool-badge {
            position: absolute;
            top: -5px;
            right: -15px;
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $main-color;
            color: #fff;
            font-size: .75rem;
            font-weight: 500;
        }
    }

    ul.navbar-nav {
        li {
            padding: .5rem;
            overflow: hidden;
        }

        .nav-link {
            position: relative;
            font-size: 1.2rem;
            text-transform: capitalize;
            padding: 0;

            &::after {
                content: "";
                position: absolute;
                bottom: -5rem;
                left: .5rem;
                height: 7px;
                width: 100%;
                background-color: #eedfdf9f;
                z-index: -1;
                transition: .3s;
            }



            &.active {
                color: $main-color;
                font-weight: bold;

                &::after {
                    bottom: .5rem;
                }
            }
        }
    }

    .cartItems {
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

.navbar-shadow {
    box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, .222);
}

.sidebar__nav {
    padding: 0;

    ul {
        list-style: none;
        padding: 0;

    }

    .nav-link {
        position: relative;
        padding: 30px;
        width: 100%;
        background-color: $main-color;
        color: #fff;
        text-transform: capitalize;
        margin-bottom: 10px;
        padding-left: 2rem !important;
        transition: .3s ease-in-out;

        &:hover {
            color: #fff !important;
            font-weight: bold;
            letter-spacing: 1.5px;

            & .nav-link-icon {
                font-size: 5rem;
                bottom: 6px;
            }
        }

        .nav-link-icon {
            position: absolute;
            right: 0;
            bottom: -20px;
            font-size: 3rem;
            color: #fff;
            transition: .3s;
        }
    }

}

.avatar {
    position: relative;

    .navbar-tool-icon-box {
        border: 5px solid rgba(0, 0, 0, 0.13);
        transition: .3s;

        &:hover {
            border-color: $main-color;
        }

        &::after {
            margin-left: 5px;
            border-color: #dedede !important;
            border-top: 0.3em solid !important;
            border-right: 0.3em solid transparent !important;
            border-bottom: 0 !important;
            border-left: 0.3em solid transparent !important;
        }

        .avatar-img {
            width: 40px;
            height: 40px;
        }

    }

    .avatar-name {
        font-size: 14px;
        transition: .3s;

        &:hover {
            color: $main-color
        }
    }


}

.search__bar {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1090;
    transition: display 0.2s 0.2s ease-in,
        opacity 0.3s 0.5s ease-in-out;

    &.show {
        display: block;
        opacity: 1;
    }

    .container {
        height: 100%;
        min-height: 100%;
        min-width: 100% !important;
    }

    .form-control,
    .form-select {
        border: 0 !important;
        background-color: transparent !important;
        font-size: 1rem;
        box-shadow: none !important;
    }

    .BTN-close {
        cursor: pointer;

        &:hover>* {
            color: $main-color
        }
    }
}