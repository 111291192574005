/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Open+Sans:wght@300;400;500&family=Rowdies:wght@300;400;700&display=swap');
/* variables Styles */
@import './variables';
/* Custom Styles */
@import './custom';
/* Colors Styles */
@import './colors';
/* forms Styles */
@import './forms';
/* global Styles */
@import './globalStyle';