@import '../../../scss/colors';

.footer {
    box-shadow: rgba(33, 35, 38, 0.3) 0px 10px 10px -10px;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 150px;
        height: 100px;
        background-color: $main-color;
        border-top-left-radius: 120%;
        border-top-right-radius: 5%;
        z-index: -1;
        opacity: 0.5;

        @media screen and (max-width: 768px) {
            content: unset;
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -25px;
        width: 250px;
        height: 200px;
        background-color: $main-color;
        border-top-right-radius: 5%;
        border-top-left-radius: 15%;
        border-bottom-right-radius: 120%;
        z-index: -1;
        box-shadow: rgba(33, 35, 38, 0.3) 0px 10px 10px 10px;

        @media screen and (max-width: 768px) {
            content: unset;
        }
    }
}

.footer-heading {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}

p {
    color: rgba(255, 255, 255, 0.3);
}

.list-unstyled li a {
    color: rgba(255, 255, 255, 0.2);
}

.subscribe-form .form-group {
    position: relative;
    margin-bottom: 0;
    border-radius: 0;
}

.subscribe-form .form-group input {
    background: white !important;
    border: none !important;
    outline: none !important;
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 16px;
    border-radius: 0;
}

.subscribe-form .form-group input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.3) !important;
}

.subscribe-form .form-group input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.3) !important;
}

.subscribe-form .form-group input:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.3) !important;
}

.subscribe-form .form-group input:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.3) !important;
}

.subscribe-form .form-group input:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.subscribe-form .form-group .submit {
    color: #fff !important;
    display: block;
    width: 130px;
    height: 52px;
    font-size: 16px;
    border: none;
    border-radius: 0;
}

.subscribe-form .form-group .submit:hover,
.subscribe-form .form-group .submit:focus {
    text-decoration: none !important;
    outline: none !important;
}

.subscribe-form .subheading {
    display: inline-block;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 13px;
}

.ftco-footer-social li a {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
}