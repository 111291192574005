// Custom size / color radios
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  background-color: rgb(245, 92, 92) !important;
  transition: background-color 0.5s ease-in-out 0s !important;
}

.form-option {
  padding-left: 0;

  &.form-check-inline {
    margin-right: ($spacer * 0.25);
  }

  .form-check-input {
    border: 0;
    background: none;

    &:checked {
      background-image: none !important;
      background: transparent;
      box-shadow: none;
    }

    &:disabled ~ .form-option-label {
      color: $text-muted;
      pointer-events: none;
    }
  }
}

.form-option-label {
  position: relative;
  margin-bottom: 0;

  padding: {
    top: 0.0625rem;
    right: $form-option-padding-x;
    left: $form-option-padding-x;
  }

  transition: $form-option-transition;
  border: $form-option-border-width solid $form-option-border-color;
  @include border-radius($form-option-border-radius);
  color: $form-option-color;

  font: {
    size: $form-option-font-size;
    weight: $form-option-font-weight;
  }

  text-align: center;
  line-height: ($form-option-size-width - 0.25rem);
  cursor: pointer;
}

.product__size {
  .option-size-sm {
    .form-option-label {
      height: auto !important;
    }
  }
}
.option-size-sm {
  .form-option-label {
    min-width: $form-option-size-width - 1rem;
    height: $form-option-size-width - 1rem;
    opacity: 0.7;
  }
  .form-check-input:checked ~ .form-option-label {
    border-color: $form-option-active-border-color;
    background-color: $form-option-active-bg;
    color: $form-option-active-color;
  }
}
.option-size-default {
  .form-option-label {
    min-width: $form-option-size-width;
    height: $form-option-size-width;
  }

  .form-check-input:checked ~ .form-option-label {
    border-color: $form-option-active-border-color;
    background-color: $form-option-active-bg;
    color: $form-option-active-color;
  }

  .form-option-label:hover {
    border-color: $form-option-hover-border-color;
    color: $form-option-hover-color;
  }
}

.form-option-color {
  .form-option-label {
    min-width: $form-option-color-width;
    height: $form-option-color-width;
  }

  .form-option-label {
    border-color: transparent;
  }

  .form-check-input:checked ~ .form-option-label {
    border-color: $form-option-border-color;
    color: $form-option-active-color;
  }
}

.form-check {
  margin-bottom: 0;
}
// Custom size / color radios
/*
.form-check-continer {
  position: relative;
  .form-check-input {
    border: 0;
    background: none;
    display: none;

    &:checked {
      background-image: none !important;
      background: transparent;
      box-shadow: none;
    }

    &:disabled ~ .form-option-label {
      color: $text-muted;
      pointer-events: none;
    }
  }

  .form-option-label {
    position: relative;
    margin-bottom: 0;
    padding-top: 0.0625rem;
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    border: 1px solid #e9e9f2;
    border-radius: 0.5rem;
    color: #5a5b75;
    font-size: 0.9375rem;
    font-weight: normal;
    text-align: center;
    line-height: 2.5rem;
    cursor: pointer;
  }
}
*/
