.bannar {
    .bannar__card {
        z-index: 5;


        @media (max-width: 768px) {
            margin-top: 9rem;
        }

        .card__content {
            @media (max-width: 575px) {
                text-align: center;
            }

            .card__content__title {
                font-family: 'Montserrat', sans-serif;
                font-size: 4rem;

                @media (max-width: 992px) and (min-width: 768px) {
                    font-size: 3rem;
                }

                @media (max-width: 575px) {
                    font-size: 2rem;
                }

            }
        }

        .card__image {
            max-height: 350px;
            z-index: -1;

            @media (max-width: 768px) {
                max-height: 300px;
            }

            @media (max-width: 992px) {
                opacity: 0.8;
            }
        }
    }
}