@import "../../scss/colors";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.085);
  border-radius: 1.5rem !important;
  overflow: hidden;

  .card__Pro__image {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 100%;
    min-height: 150px;
    height: 170px;
    padding-top: 20px;
    .card-img {
      display: block;
      text-align: center;
      width: 160px;
      height: 100%;
      z-index: 2;
    }

    .product__options {
      position: absolute;
      left: 10px;
      border-radius: 20px;
      padding: 7px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 1rem;
      z-index: -1;
      opacity: 0;
      transform: translateX(-200px);
      transition: all 0.5s ease-in;

      // Medium devices (tablets, 768px and up)
      @media (max-width: 768px) {
        opacity: 1;
        transform: translateX(0px);
        z-index: 22;
        flex-direction: row;
        left: 20px;
        top: 0;
      }
    }
  }

  &:hover {
    & .product__options {
      opacity: 1;
      transform: translateX(0px);
      z-index: 22;
    }
  }
}

.card-product {
  // max-width: 20rem;
  margin-right: auto;
  margin-left: auto;
  align-items: center;

  .card-body {
    padding: 0.75rem 1.25rem;
    text-align: center;
    width: 100%;
    overflow: hidden;
    max-height: 150px;

    .meta-link {
      transition: color 0.2s ease-in-out;
      color: #797a95;
      vertical-align: middle;
      text-decoration: none;
      display: inline-block;
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: transparent !important;
    padding-top: 0.5rem;
    padding-right: 1.25rem;
    padding-bottom: 1.375rem;
    padding-left: 1.25rem;
    border: 0;

    .star-rating {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .btn-wishlist {
    color: #9e9fb4;
    font-size: 1.25rem;
  }

  .btn-divider {
    display: inline-block;
    width: 1px;
    height: 1.5rem;
    margin: 0 0.5rem;
    background-color: #e9e9f2;
  }

  .btn-addtocart {
    color: $main-color;
    font-size: 1.375rem;
    white-space: nowrap;
    border: none;
    background-color: transparent;
    padding: 0;
  }
}

.card-hover {
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.card.card-hover:hover,
.card.card-active {
  border-color: rgba(118, 109, 244, 0.35) !important;
  box-shadow: 0.1875rem 0.1875rem 0.625rem -0.125rem rgba(0, 0, 0, 0.25) !important;
}

.ellipsis {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
