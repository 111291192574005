@import "../../scss/colors";

.hero__section {
  min-height: calc(100vh - 100px);
  overflow: hidden;

  .imgContainer {
    margin: auto;
    position: relative;
    // overflow: hidden;
    // width: 500px;
    // height: 500px;
    // background-color: $main-color;
    // padding: 20px;
    border-radius: 50%;
    text-align: center;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      top: 5rem;
      left: 10%;
      filter: drop-shadow(0px -8px 5px rgba(0, 0, 0, 0.425));

      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      @media screen and (max-width: 990px) {
        width: 80%;
        top: 1rem;
        bottom: 0;
      }
    }

    .imgBgShape {
      path {
        fill: $main-color !important;
      }
    }
  }

  .Hero__Title {
    font-size: 7rem;
    font-family: "Montserrat", sans-serif;

    @media screen and (max-width: 990px) {
      font-size: 4rem;
    }
  }

  /* Shape Bg */
  .shape {
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 2;
  }

  .shape-top,
  .shape-bottom {
    width: 100%;
    left: 0;
  }

  .shape-bottom {
    bottom: 0;
    margin-bottom: -1px;
  }

  .shape-curve {
    padding-bottom: 0;
  }

  .shape.bg-light,
  .shape.bg-body,
  .shape.bg-primary,
  .shape.bg-secondary,
  .shape.bg-dark,
  .shape.bg-darker {
    background-color: transparent !important;
  }

  .shape.bg-body {
    color: #fff !important;
  }
}
