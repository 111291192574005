.loading {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}